// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
// components
import { StyledChart } from "./components/chart";
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import { useAuthContext } from "./auth/useAuthContext";
import LoadingScreen from "./components/LoadingScreen";

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuthContext();
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <SnackbarProvider>
            <StyledChart />
            <Router />
          </SnackbarProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
