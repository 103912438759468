import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import { useSettingsContext } from "../../components/settings";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import NavHorizontal from "./nav/NavHorizontal";
import MainPlanner from "./MainPlanner";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const location = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const isPlanner = location.pathname === "/dashboard/planner";
  const [open, setOpen] = useState(false);
  const isNavHorizontal = themeLayout === "horizontal";
  const isNavMini = themeLayout === "mini";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} />
  );

  if (isNavHorizontal) {
    return (
      <>
        {!isPlanner && <Header onOpenNav={handleOpen} />}

        {isPlanner ? "" : isDesktop ? <NavHorizontal /> : renderNavVertical}

        {isPlanner ? (
          <MainPlanner>
            <Outlet />
          </MainPlanner>
        ) : (
          <Main>
            <Outlet />
          </Main>
        )}
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        {!isPlanner && <Header onOpenNav={handleOpen} />}
        <Box
          sx={{
            display: { lg: "flex" },
            minHeight: { lg: 1 },
          }}
        >
          {isPlanner ? "" : isDesktop ? <NavMini /> : renderNavVertical}
          {isPlanner ? (
            <MainPlanner>
              <Outlet />
            </MainPlanner>
          ) : (
            <Main>
              <Outlet />
            </Main>
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      {!isPlanner && <Header onOpenNav={handleOpen} />}

      <Box
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
        }}
      >
        {!isPlanner && renderNavVertical}
        {isPlanner ? (
          <MainPlanner>
            <Outlet />
          </MainPlanner>
        ) : (
          <Main>
            <Outlet />
          </Main>
        )}
      </Box>
    </>
  );
}
