import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config";
//
import {
  // Auth
  LoginPage,
  GeneralAppPage,
  UserListPage,
  UserEditPage,
  UserCreatePage,
  UserAccountPage,
  InvoiceDetailsPage,
  PodDetailsPage,
  PodEditPage,
  InvoiceEditPage,
  Page500,
  Page403,
  Page404,
  JobListPage,
  JobDetailPage,
  DeliveryPlannerPage,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "jobs", element: <JobListPage /> },
        { path: "planner", element: <DeliveryPlannerPage /> },
        { path: "jobs/:jobID/detail", element: <JobDetailPage /> },
        { path: "app", element: <GeneralAppPage /> },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/list" replace />,
              index: true,
            },
            { path: "list", element: <UserListPage /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":name/edit", element: <UserEditPage /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/view" replace />,
              index: true,
            },
            { path: "view", element: <InvoiceDetailsPage /> },
            { path: "edit", element: <InvoiceEditPage /> },
          ],
        },
        {
          path: "pod",
          children: [
            {
              element: <Navigate to="/dashboard/pod/view" replace />,
              index: true,
            },
            { path: "view", element: <PodDetailsPage /> },
            { path: "edit", element: <PodEditPage /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to="/dashboard/" replace />,
          index: true,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
