// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components

import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------
//
const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    items: [
      // USER
      {
        title: "user",
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: "list", path: PATH_DASHBOARD.user.list },
          { title: "create", path: PATH_DASHBOARD.user.new },
          { title: "account", path: PATH_DASHBOARD.user.account },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: "app",
    items: [
      {
        title: "job",
        path: PATH_DASHBOARD.jobs.root,
        icon: ICONS.menuItem,
      },
      {
        title: "dashboard",
        path: PATH_DASHBOARD.dashboard.root,
        icon: ICONS.analytics,
      },
      {
        title: "Invoicing",
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: "view", path: PATH_DASHBOARD.invoice.view },
          { title: "edit", path: PATH_DASHBOARD.invoice.edit },
        ],
      },
      {
        title: "proof of delivery",
        path: PATH_DASHBOARD.pod.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "view", path: PATH_DASHBOARD.pod.view },
          { title: "edit", path: PATH_DASHBOARD.pod.edit },
        ],
      },
      {
        title: "planner",
        path: PATH_DASHBOARD.planner.root,
        icon: ICONS.calendar,
      },
    ],
  },
];

export default navConfig;
