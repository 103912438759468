import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));

export const JobListPage = Loadable(lazy(() => import('../pages/dashboard/JobListPage')));
export const JobDetailPage = Loadable(lazy(() => import('../pages/dashboard/JobDetailPage')));

export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

export const InvoiceDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceDetailsPage'))
);
export const InvoiceCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceCreatePage'))
);

export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));
export const PodEditPage = Loadable(lazy(() => import('../pages/dashboard/PodEdit')));
export const PodCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/PodCreatePage'))
);
export const PodDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/PodDetailsPage'))
);

export const DeliveryPlannerPage = Loadable(
  lazy(() => import('../pages/dashboard/DeliveryPlanner'))
);

export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
